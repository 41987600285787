import { Component, Input } from '@angular/core';
import { CognitivelyImpairedPainService } from '../../lib/cognitively-impaired-pain.service';
import { CommonUtilsService } from '../../lib/common-utils.service';
import { LhcDataService } from '../../lib/lhc-data.service';
import { QUESTION_CODE } from '../../lib/models/wound-measure.interface';

@Component({
  selector: 'sm-item',
  templateUrl: './sm-item.component.html',
  styleUrls: ['./sm-item.component.css'],
})
export class SmItemComponent {
  @Input() item;
  public questionCode = QUESTION_CODE;

  constructor(
    public lhcDataService: LhcDataService,
    public commonUtilsService: CommonUtilsService,
    public cognitivelyImpairedPainService: CognitivelyImpairedPainService
  ) { }

  getStringify(jsonToStingify: string) {
    return JSON.stringify(jsonToStingify);
  }

  isArray(obj: any): boolean {
    return Array.isArray(obj);
  }
}
