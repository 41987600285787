<ng-container *ngIf="item && lhcDataService.targetShown(item)">
  <ng-container *ngIf="item.questionCode === questionCode.surroundingPainFreq && !cognitivelyImpairedPainService.isHidden">
    <div class="pain-ad-score">
      Pain AD Score:  
      {{
      cognitivelyImpairedPainService.cognitivelyImpairedPainADScore !== -1
      ? cognitivelyImpairedPainService.cognitivelyImpairedPainADScore
      : 'INC'
      }}
    </div>
  </ng-container>

  <!-- 'smart-tissue' -->
  <ng-container *ngIf="item.questionCode === questionCode.smartTissue">
    <sm-smart-tissue *ngIf="(smartTissue$ | async) != null" [show]="showSmartTissue$ | async" [items]="items$ | async"
      [image]="image$ | async" [imageMask]="imageMask$ | async"
      [hasFailed]="smartTissueHasFailed$ | async"></sm-smart-tissue>
  </ng-container>

  <!-- 'body-picker' -->
  <ng-container *ngIf="item.questionCode === questionCode.bodyPicker">
    <sm-body-location [bodyLocation]="bodyLocation$ | async" 
    [item]="item"></sm-body-location>
  </ng-container>

  <!-- 'wound-care-order-picker' -->
  <ng-container *ngIf="item.questionCode === questionCode.woundCareOrderPicker">
    <sm-wound-care-order-picker [woundCareOrder]="woundCareOrder$ | async" 
    [item]="item"></sm-wound-care-order-picker>
  </ng-container>

  <ng-container [ngSwitch]="item.dataType">
    <!-- 'group' -->
    <ng-container *ngSwitchCase="'SECTION'">
      <lhc-item-group [item]="item"></lhc-item-group>
    </ng-container>
    <!-- 'display' -->
    <ng-container *ngSwitchCase="'TITLE'">
      <ng-container>
        <lhc-item-display [item]="item"></lhc-item-display>
      </ng-container>
      <ng-container *ngIf="item.questionCode === questionCode.scoringType && (questionnaireDataService$ | async)">
        <div class="scoring-type">
          {{ (scoringTypes$ | async) }}
        </div>
      </ng-container>
    </ng-container>

    <!-- 'question' -->
    <ng-container *ngSwitchDefault>
      <lhc-item-question *ngIf="item.questionCode !== bodyLocationQuestionCode &&
       item.questionCode !== woundCareOrderQuestionCode && 
       item.questionCode !== smartTissueQuestionCode"
       [item]="item" class="lhc-de" [ngStyle]="lhcDataService.getItemStyle(item)">
      </lhc-item-question>
      <!-- question item could have sub items -->
      <div *ngIf="item.items" class="lhc-item-group-body">
        <ng-container *ngFor="let item of item.items; trackBy: lhcDataService.trackByElementId">
          <lhc-item *ngIf="item && lhcDataService.targetShown(item) && !item._isHiddenFromView" [item]="item"
            class="{{lhcDataService.getItemClassList(item, viewMode)}}" id="item-{{ item._elementId }}">
          </lhc-item>
        </ng-container>
      </div>
      <!-- a button at the end of each repeating section -->
      <div class="lhc-button-padding {{lhcDataService.getSiblingStatus(item)}}"
        *ngIf="item._lastRepeatingItem && lhcDataService.targetShown(item) && !item._isHiddenFromView">
        <div class="{{lhcDataService.getSkipLogicClass(item)}}">
          <lhc-button-popover [item]="item" [popoverType]="'repeating-item'"
            buttonLabel='+ Add another "{{item._text}}"'></lhc-button-popover>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>