import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { QuestionnaireData } from './models/questionnaire.interface';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireDataService {
  private questionnaireData$ = new BehaviorSubject<QuestionnaireData | null>(
    null
  );

  constructor() {
    window['QuestionnaireDataService'] = this;
  }

  getQuestionnaireData(): Observable<QuestionnaireData | null> {
    return this.questionnaireData$.asObservable().pipe(distinctUntilChanged());
  }

  setQuestionnaireData(data: QuestionnaireData): void {
    this.questionnaireData$.next(data);
  }
}
