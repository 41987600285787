import { Component, Input, OnInit } from '@angular/core';
import { LhcDataService } from '../../lib/lhc-data.service';
import {
  SmartTissueImage,
  SmartTissueItem,
} from '../../lib/models/smartTissue.interface';
import { CommonUtilsService } from '../../lib/common-utils.service';

@Component({
  selector: 'sm-smart-tissue',
  templateUrl: './sm-smart-tissue.component.html',
  styleUrls: ['./sm-smart-tissue.component.css'],
})
export class SmSmartTissueComponent implements OnInit {
  @Input() show: boolean;
  @Input() items: SmartTissueItem[];
  @Input() image: SmartTissueImage;
  @Input() imageMask: SmartTissueImage;
  @Input() hasFailed: boolean;

  constructor(private lhcDataService: LhcDataService,
    public commonUtils: CommonUtilsService) {}

  ngOnInit(): void {}

  applyAction() {
    this.setSmartItemValuesAsSuggested(false);
  }

  declineAction() {
    this.setSmartItemValuesAsSuggested(true);
  }

  private setSmartItemValuesAsSuggested(isDecline: boolean): void {
    let formWithSmartTissueData = this.lhcDataService.getLhcFormData();

    setTimeout(() => {
      this.lhcDataService.setLhcFormData(null);
    });

    setTimeout(() => {
      this.findAndSetItemAsSuggested(formWithSmartTissueData.items, isDecline);
      this.lhcDataService.setLhcFormData(formWithSmartTissueData);
    });
  }

  private findAndSetItemAsSuggested(items: any, isDecline: boolean): any {
    // Make the changes for each answer setted as suggested
    items.forEach((item) => {
      if (item.questionCode) {
        const smartItem = this.items.find(
          (smartItem) => smartItem.questionCode === item.questionCode
        );
        if (smartItem) {
          var newValue = undefined;
          var previousValue = item.value;
          var answerValue = item.answers.find((answer) => {
            if (answer.code === smartItem.answerCode) {
              answer.helpText = 'Suggested';
              return answer;
            }
          });
          if (answerValue && !isDecline) {
            newValue = {
              code: smartItem.answerCode,
              text: answerValue?.text,
              helpText: 'Suggested',
            };
          }
          item.value = newValue;
          this.lhcDataService.onItemValueChange(
            item,
            newValue,
            previousValue
          );
          return item.value;
        } else {
          if (item.items && item.items.length > 0) {
            const value = this.findAndSetItemAsSuggested(
              item.items,
              isDecline
            ); // Recursive call to review items inside an item
            if (value) {
              return value;
            }
          }
        }
      }
    });
  }
}
