
<ng-container [ngSwitch]="item.dataType">
    <ng-container *ngIf="item.questionCode === questionCode.surroundingPainFreq && !cognitivelyImpairedPainService.isHidden">
        <p class="pain-ad-score summary-answer">
            Pain AD Score:  
            {{
            cognitivelyImpairedPainService.cognitivelyImpairedPainADScore !== -1
            ? cognitivelyImpairedPainService.cognitivelyImpairedPainADScore
            : 'INC'
            }}
        </p>
    </ng-container>
    <ng-container *ngSwitchCase="'SECTION'">
        <h3 class="summary-title" *ngIf="item.question">{{ item.question }}</h3>
    </ng-container>
    <ng-container *ngSwitchCase="'CNE'">
        <ng-container *ngTemplateOutlet="radioSelection; context: {item}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'CWE'">
        <ng-container *ngTemplateOutlet="radioSelection; context: {item}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'BL'">
        <ng-container *ngIf="item.question && item.value !== undefined">
            <p class="summary-question"><b>{{item.question}}</b></p>
            <p class="summary-answer">{{item.value ? 'Yes' : 'No'}}</p>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'DT'">
        <ng-container *ngIf="item.question && item.value !== undefined">
            <p class="summary-question"><b>{{item.question}}</b></p>
            <p class="summary-answer">{{ commonUtilsService.getDisplayText(item.value | date: 'MMM dd, yyyy') }}</p>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'DTM'">
        <ng-container *ngIf="item.question && item.value !== undefined">
            <p class="summary-question"><b>{{item.question}}</b></p>
            <p class="summary-answer">{{ commonUtilsService.getDisplayText(item.value | date: 'MMM dd, yyyy HH:mm:ss') }}</p>
        </ng-container>
    </ng-container>
    <!-- 'ST' || 'REAL' || 'INT' || 'TM' || 'TX' || 'QTY' -->
    <ng-container *ngSwitchDefault="">
        <ng-container *ngIf="item.question && item.value !== undefined">
            <p class="summary-question"><b>{{item.question}}</b></p>
            <p class="summary-answer">{{ commonUtilsService.getDisplayText(item.value) }}</p>
        </ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="item?.items">
    <ng-container *ngFor="let subItem of item.items">
        <sm-item class="{{ lhcDataService.getItemClassList(subItem, viewMode) }}" id="item-{{ subItem._elementId }}" *ngIf="
        subItem && 
        lhcDataService.targetShown(subItem) &&
        !subItem._isHiddenFromView &&
        !lhcDataService.isSubsequentHorizontalTableGroupItem(subItem)
      " [item]="subItem"></sm-item>
    </ng-container>
</ng-container>

<ng-template #radioSelection let-item="item">
    <ng-container *ngIf="item.question">
        <ng-container *ngIf="item.value?.text">
            <p class="summary-question"><b>{{item.question}}</b></p>
            <p class="summary-answer">{{ commonUtilsService.getDisplayText(item.value.text) }}</p>
        </ng-container>
        <ng-container *ngIf="isArray(item.value)">
            <p class="summary-question"><b>{{item.question}}</b></p>
            <p class="summary-answer">
                <ng-container *ngFor="let answer of item.value; last as isLast">{{ commonUtilsService.getDisplayText(answer.text) }}{{ isLast ? '' : ', ' }}</ng-container>
            </p>
        </ng-container>
    </ng-container>
</ng-template>