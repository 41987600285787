import { Injectable } from '@angular/core';
import { WoundMeasureService } from './wound-measure.service';
import { UnderminingService } from './undermining.service';
import {
  ANSWERS_CODES_TISSUE_ESCHAR_RANGES,
  ANSWERS_CODES_TISSUE_SLOUGH_RANGES,
  ANSWER_CODE,
  BWAT_SCORE_THRESHOLD,
  EPITHELIALIZATION_TISSUE_VALUES_SCORE,
  EXUDATE_AMOUNT_VALUES_SCORE,
  EXUDATE_TYPE_VALUES_SCORE,
  GRANULATION_TISSUE_VALUES_SCORE,
  PERIPHERAL_EDEMA_VALUES_SCORE,
  PERIPHERAL_TISSUE_INDURATION_VALUES_SCORE,
  QUESTION_CODE,
  SKIN_COLOR_VALUES_SCORE,
  TISSUE_DEPTH_VALUES_SCORE,
  TISSUE_EDGES_VALUES_SCORE,
  TISSUE_ESCHAR_RANGES,
  TISSUE_SLOUGH_RANGES,
} from './models/wound-measure.interface';

declare function sendMessageToFlutter(event, item): void;

@Injectable({
  providedIn: 'root',
})
export class BwatScoreService {
  private bwatScore: number = -1;
  private jsonQuestionnaire: any = null;

  constructor(
    private woundMeasureService: WoundMeasureService,
    private underminingService: UnderminingService
  ) {
    window['BwatScoreService'] = this;
  }

  setQuestionnaireResponse(data: any): void {
    this.jsonQuestionnaire = data;
    if (this.calculateBwatScore() !== this.bwatScore && !!this.bwatScore) {
      this.bwatScore = this.calculateBwatScore();
      console.log('BWAT Score Changed: ', this.bwatScore);
      // Send message to flutter through the channel on JS side on questionnaire.html
      sendMessageToFlutter('bwatScoreChanged', { bwatScore: this.bwatScore });
    }
  }

  private findValueCodeForQuestionCode(
    items: any[],
    questionCode: string
  ): string {
    for (const item of items) {
      if (item.questionCode === questionCode) {
        return item.value?.code ?? item.value;
      }
      if (item.items && item.items.length > 0) {
        const value = this.findValueCodeForQuestionCode(
          item.items,
          questionCode
        ); // Recursive call to review items inside an item
        if (value) {
          return value;
        }
      }
    }
    return ''; // Return empty string if value is not found
  }

  // Make calculation for BWAT score here
  private calculateBwatScore(): number {
    if (this.jsonQuestionnaire !== null) {
      if (this.woundMeasureService.areaScore() === -1) {
        return -1;
      } else {
        // 1. Size
        const TISSUE_SIZE_SCORE = this.calculateSizeScore();
        console.log('TISSUE_SIZE_SCORE', TISSUE_SIZE_SCORE);
        // 2. Depth
        const TISSUE_DEPTH_SCORE = this.calculateScoreForCharacteristic(
          QUESTION_CODE.tissueDepth
        );
        console.log('TISSUE_DEPTH_SCORE', TISSUE_DEPTH_SCORE);
        // 3. Edges
        const TISSUE_EDGES_SCORE = this.calculateScoreForCharacteristic(
          QUESTION_CODE.woundMargins
        );
        console.log('TISSUE_EDGES_SCORE', TISSUE_EDGES_SCORE);
        // 4. Undermining
        const UNDERMINING_SCORE = this.underminingService.underminingScore();
        console.log('UNDERMINING_SCORE', UNDERMINING_SCORE);
        // 5. Necrotic Tissue Type
        const NECROTIC_TISSUE_TYPE_SCORE = this.necroticTissueTypeScore();
        console.log('NECROTIC_TISSUE_TYPE_SCORE', NECROTIC_TISSUE_TYPE_SCORE);
        // 6. Necrotic Tissue Amount
        const NECROTIC_TISSUE_AMOUNT_SCORE = this.necroticTissueAmountScore();
        console.log('NECROTIC_TISSUE_AMOUNT_SCORE', NECROTIC_TISSUE_AMOUNT_SCORE);
        // 7. Exudate Type
        const TISSUE_EXUDATE_TYPE_SCORE = this.calculateScoreForCharacteristic(
          QUESTION_CODE.exudateType
        );
        console.log('TISSUE_EXUDATE_TYPE_SCORE', TISSUE_EXUDATE_TYPE_SCORE);
        // 8. Exudate Amount
        const TISSUE_EXUDATE_AMOUNT_SCORE =
          this.calculateScoreForCharacteristic(QUESTION_CODE.exudateAmount);
        console.log('TISSUE_EXUDATE_AMOUNT_SCORE', TISSUE_EXUDATE_AMOUNT_SCORE);
        // 9. Skin Color Surrounding Wound Tissue
        const SKIN_COLOR_SURROUNDING_WOUND_TISSUE_SCORE =
          this.calculateScoreForCharacteristic(QUESTION_CODE.skinColor);
        console.log('SKIN_COLOR_SURROUNDING_WOUND_TISSUE_SCORE', SKIN_COLOR_SURROUNDING_WOUND_TISSUE_SCORE);
        // 10. Peripheral Tissue Edema
        const PERIPHERAL_EDEMA_SCORE = this.calculateScoreForCharacteristic(
          QUESTION_CODE.edema
        );
        console.log('PERIPHERAL_EDEMA_SCORE', PERIPHERAL_EDEMA_SCORE);
        // 11. Peripheral Tissue Induration
        const PERIPHERAL_TISSUE_INDURATION_SCORE =
          this.calculateScoreForCharacteristic(QUESTION_CODE.induration);
        console.log('PERIPHERAL_TISSUE_INDURATION_SCORE', PERIPHERAL_TISSUE_INDURATION_SCORE);
        // 12. Granulation Tissue
        const GRANULATION_TISSUE_SCORE = this.calculateScoreForCharacteristic(
          QUESTION_CODE.granulationPercentage
        );
        console.log('GRANULATION_TISSUE_SCORE', GRANULATION_TISSUE_SCORE);
        // 13. Epithelialization Tissue
        const EPITHELIAL_TISSUE_SCORE = this.calculateScoreForCharacteristic(
          QUESTION_CODE.epithelialPercentage
        );
        console.log('EPITHELIAL_TISSUE_SCORE', EPITHELIAL_TISSUE_SCORE);

        let totalScore: number = 0;
        if (
          TISSUE_SIZE_SCORE === -1 &&
          TISSUE_DEPTH_SCORE === -1 &&
          TISSUE_EDGES_SCORE === -1 &&
          UNDERMINING_SCORE === -1 &&
          NECROTIC_TISSUE_TYPE_SCORE === -1 &&
          NECROTIC_TISSUE_AMOUNT_SCORE === -1 &&
          TISSUE_EXUDATE_TYPE_SCORE === -1 &&
          TISSUE_EXUDATE_AMOUNT_SCORE === -1 &&
          SKIN_COLOR_SURROUNDING_WOUND_TISSUE_SCORE === -1 &&
          PERIPHERAL_EDEMA_SCORE === -1 &&
          PERIPHERAL_TISSUE_INDURATION_SCORE === -1 &&
          GRANULATION_TISSUE_SCORE === -1 &&
          EPITHELIAL_TISSUE_SCORE === -1
        ) {
          totalScore = -1;
        } else {
          totalScore += this.sanitizeValueForSum(TISSUE_SIZE_SCORE);
          totalScore += this.sanitizeValueForSum(TISSUE_DEPTH_SCORE);
          totalScore += this.sanitizeValueForSum(TISSUE_EDGES_SCORE);
          totalScore += this.sanitizeValueForSum(UNDERMINING_SCORE);
          totalScore += this.sanitizeValueForSum(NECROTIC_TISSUE_TYPE_SCORE);
          totalScore += this.sanitizeValueForSum(NECROTIC_TISSUE_AMOUNT_SCORE);
          totalScore += this.sanitizeValueForSum(TISSUE_EXUDATE_TYPE_SCORE);
          totalScore += this.sanitizeValueForSum(TISSUE_EXUDATE_AMOUNT_SCORE);
          totalScore += this.sanitizeValueForSum(SKIN_COLOR_SURROUNDING_WOUND_TISSUE_SCORE);
          totalScore += this.sanitizeValueForSum(PERIPHERAL_EDEMA_SCORE);
          totalScore += this.sanitizeValueForSum(PERIPHERAL_TISSUE_INDURATION_SCORE);
          totalScore += this.sanitizeValueForSum(GRANULATION_TISSUE_SCORE);
          totalScore += this.sanitizeValueForSum(EPITHELIAL_TISSUE_SCORE);
        }
        return totalScore;
      }
    }
  }

  private sanitizeValueForSum(value: number): number {
    if (typeof(value) === 'number' && value > -1) {
      return value;
    } else {
      return 0;
    }
  }

  private calculateSizeScore(): number {
    let result: number = -1;
    const areaScore = this.woundMeasureService.areaScore(BWAT_SCORE_THRESHOLD);
    const isWoundClosed = this.underminingService.getIsWoundClosed();
    const hasTrace = this.underminingService.getHasTrace();

    if (isWoundClosed) {
      result = 0;
    } else if (!isWoundClosed && !hasTrace) {
      result = -1;
    } else if (areaScore) {
      result = areaScore;
    }
    return result;
  }

  private calculateScoreForCharacteristic(questionCode: string): number {
    let result: number = -1;
    const valueForQuestionCode = this.findValueCodeForQuestionCode(
      this.jsonQuestionnaire.items,
      questionCode
    );
    const isWoundClosed = this.underminingService.getIsWoundClosed();

    if (!valueForQuestionCode && !isWoundClosed) {
      return -1;
    } else if (isWoundClosed) {
      return 0;
    } else {
      switch (questionCode) {
        case QUESTION_CODE.tissueDepth:
          return TISSUE_DEPTH_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.woundMargins: // Edges
          return TISSUE_EDGES_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.tissueEschar:
          return EXUDATE_TYPE_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.exudateType:
          return EXUDATE_TYPE_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.exudateAmount:
          return EXUDATE_AMOUNT_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.skinColor:
          return SKIN_COLOR_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.edema:
          return PERIPHERAL_EDEMA_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.induration:
          return PERIPHERAL_TISSUE_INDURATION_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.granulationPercentage:
          return GRANULATION_TISSUE_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.epithelialPercentage:
          return EPITHELIALIZATION_TISSUE_VALUES_SCORE[valueForQuestionCode];
      }
    }
    return result;
  }

  private returnEscharAndSlough(): { eschar: string; slough: string } {
    const slough = this.findValueCodeForQuestionCode(
      this.jsonQuestionnaire.items,
      QUESTION_CODE.tissueSlough
    );
    const eschar = this.findValueCodeForQuestionCode(
      this.jsonQuestionnaire.items,
      QUESTION_CODE.tissueEschar
    );
    return { eschar, slough };
  }

  private returnEscharAndSloughPercentages(): {
    escharPercentage: string;
    sloughPercentage: string;
  } {
    const sloughPercentage = this.findValueCodeForQuestionCode(
      this.jsonQuestionnaire.items,
      QUESTION_CODE.sloughPercentage
    );
    const escharPercentage = this.findValueCodeForQuestionCode(
      this.jsonQuestionnaire.items,
      QUESTION_CODE.escharPercentage
    );
    return { escharPercentage, sloughPercentage };
  }

  private necroticTissueTypeScore(): number {
    let result: number = -1;
    const { slough, eschar } = this.returnEscharAndSlough();

    if (!slough || !eschar) {
      result = -1;
    } else if (slough === ANSWER_CODE.none && eschar === ANSWER_CODE.none) {
      result = 1;
    } else {
      const { sloughPercentage, escharPercentage } =
        this.returnEscharAndSloughPercentages();
      if ((!sloughPercentage && slough !== ANSWER_CODE.none) || (!escharPercentage && eschar!== ANSWER_CODE.none)) {
        result = -1;
      } else if (
        sloughPercentage === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        escharPercentage === ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none
      ) {
        result = 1;
      } else if (
        slough === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.whiteNonAdherentYellow &&
        sloughPercentage !== TISSUE_SLOUGH_RANGES.tissueSlough0 &&
        eschar === ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none
      ) {
        result = 2;
      } else if (
        slough === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.whiteNonAdherentYellow &&
        sloughPercentage !== TISSUE_SLOUGH_RANGES.tissueSlough0 &&
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        this.evaluateIfOnePercentageIsGreaterThanSecondOne(
          sloughPercentage,
          escharPercentage
        )
      ) {
        result = 2;
      } else if (
        slough === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.looseAdherentYellow &&
        sloughPercentage !== TISSUE_SLOUGH_RANGES.tissueSlough0 &&
        eschar === ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none
      ) {
        result = 3;
      } else if (
        slough === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.looseAdherentYellow &&
        sloughPercentage !== TISSUE_SLOUGH_RANGES.tissueSlough0 &&
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        this.evaluateIfOnePercentageIsGreaterThanSecondOne(
          sloughPercentage,
          escharPercentage
        )
      ) {
        result = 3;
      } else if (
        eschar ===
          ANSWERS_CODES_TISSUE_ESCHAR_RANGES.tissueEscharAdherantSoftBlack &&
        escharPercentage !== TISSUE_ESCHAR_RANGES.tissueEschar0 &&
        slough === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none
      ) {
        result = 4;
      } else if (
        eschar ===
          ANSWERS_CODES_TISSUE_ESCHAR_RANGES.tissueEscharAdherantSoftBlack &&
        escharPercentage !== TISSUE_ESCHAR_RANGES.tissueEschar0 &&
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        this.evaluateIfOnePercentageIsGreaterOrEqualThanSecondOne(
          escharPercentage,
          sloughPercentage
        )
      ) {
        result = 4;
      } else if (
        eschar ===
          ANSWERS_CODES_TISSUE_ESCHAR_RANGES.tissueEscharAdherantFirmBlack &&
        escharPercentage !== TISSUE_ESCHAR_RANGES.tissueEschar0 &&
        slough === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none
      ) {
        result = 5;
      } else if (
        eschar ===
          ANSWERS_CODES_TISSUE_ESCHAR_RANGES.tissueEscharAdherantFirmBlack &&
        escharPercentage !== TISSUE_ESCHAR_RANGES.tissueEschar0 &&
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        this.evaluateIfOnePercentageIsGreaterOrEqualThanSecondOne(
          escharPercentage,
          sloughPercentage
        )
      ) {
        result = 5;
      }
    }
    return result;
  }

  private necroticTissueAmountScore(): number {
    let result: number = -1;
    const { slough, eschar } = this.returnEscharAndSlough();

    if (!slough || !eschar) {
      result = -1;
    } else if (slough === ANSWER_CODE.none && eschar === ANSWER_CODE.none) {
      result = 1;
    } else {
      const { sloughPercentage, escharPercentage } =
        this.returnEscharAndSloughPercentages();
      if ((!sloughPercentage && slough!== ANSWER_CODE.none) || (!escharPercentage && eschar!== ANSWER_CODE.none)) {
        result = -1;
      } else if (
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        (sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough0 ||
          sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough0To25) &&
        eschar === ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none
      ) {
        result = 2;
      } else if (
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        (sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough0 ||
          sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough0To25) &&
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        (escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar0 ||
          escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar0To25)
      ) {
        result = 2;
      } else if (
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        (escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar0 ||
          escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar0To25) &&
        slough === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none
      ) {
        result = 2;
      } else if (
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        (escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar0 ||
          escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar0To25) &&
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        (sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough0 ||
          sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough0To25)
      ) {
        result = 2;
      } else if (
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough25To50 &&
        eschar === ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none
      ) {
        result = 3;
      } else if (
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough25To50 &&
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        (escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar0 ||
          escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar0To25 ||
          escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar25To50)
      ) {
        result = 3;
      } else if (
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar25To50 &&
        slough === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none
      ) {
        result = 3;
      } else if (
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar25To50 &&
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        (sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough0 ||
          sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough0To25 ||
          sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough25To50)
      ) {
        result = 3;
      } else if (
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough50To75 &&
        eschar === ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none
      ) {
        result = 4;
      } else if (
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough50To75 &&
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        (escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar0 ||
          escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar0To25 ||
          escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar25To50 ||
          escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar50To75)
      ) {
        result = 4;
      } else if (
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar50To75 &&
        slough === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none
      ) {
        result = 4;
      } else if (
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar50To75 &&
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        (sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough0 ||
          sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough0To25 ||
          sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough25To50 ||
          sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough50To75)
      ) {
        result = 4;
      } else if (
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough75To100 &&
        eschar === ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none
      ) {
        result = 5;
      } else if (
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough100 &&
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none
      ) {
        result = 5;
      } else if (
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough75To100 &&
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        escharPercentage !== TISSUE_ESCHAR_RANGES.tissueEschar100
      ) {
        result = 5;
      } else if (
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        sloughPercentage === TISSUE_SLOUGH_RANGES.tissueSlough100 &&
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        escharPercentage !== ''
      ) {
        result = 5;
      } else if (
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar75To100 &&
        slough === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none
      ) {
        result = 5;
      } else if (
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        escharPercentage === TISSUE_ESCHAR_RANGES.tissueEschar100 &&
        slough === ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none
      ) {
        result = 5;
      } else if (
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        escharPercentage !== TISSUE_ESCHAR_RANGES.tissueEschar75To100 &&
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        sloughPercentage !== TISSUE_SLOUGH_RANGES.tissueSlough100
      ) {
        result = 5;
      } else if (
        eschar !== ANSWERS_CODES_TISSUE_ESCHAR_RANGES.none &&
        escharPercentage !== TISSUE_ESCHAR_RANGES.tissueEschar100 &&
        slough !== ANSWERS_CODES_TISSUE_SLOUGH_RANGES.none &&
        sloughPercentage !== TISSUE_SLOUGH_RANGES.tissueSlough100
      ) {
        result = 5;
      }
    }
    return result;
  }

  private evaluateIfOnePercentageIsGreaterThanSecondOne(
    firstPercentage: string,
    secondPercentage: string
  ): boolean {
    if (
      this.getPositionOfRange(firstPercentage) >
      this.getPositionOfRange(secondPercentage)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private evaluateIfOnePercentageIsGreaterOrEqualThanSecondOne(
    firstPercentage: string,
    secondPercentage: string
  ): boolean {
    if (
      this.getPositionOfRange(firstPercentage) >=
      this.getPositionOfRange(secondPercentage)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getPositionOfRange(percentage: string): number {
    let useThisRange;
    if (percentage) {
      if (percentage.includes('tissueSlough')) {
        useThisRange = TISSUE_SLOUGH_RANGES;
      } else if (percentage.includes('tissueEschar')) {
        useThisRange = TISSUE_ESCHAR_RANGES;
      }
      let index = 0;
      for (const range in useThisRange) {
        if (useThisRange[range] === percentage) {
          return index;
        }
        index++;
      }
    }
    return 0;
  }
}
