<ng-container *ngIf="item">
  <nz-radio-group 
    [ngModel]="radioValue" 
    (ngModelChange)="onModelChange($event)"
    [nzDisabled]="item._readOnly"
    role="radiogroup"
    [nzName]="'radiogroup_' + item._elementId"
    attr.aria-labelledby="label-{{ item._elementId }}"
    (click)="lhcDataService.setActiveRow(item)">
    <label 
      nz-radio 
      [nzValue]="true"
      (click)="checkIfDoubleSelection(true)"
      [(ngModel)]="booleanModels[0]" 
      [id]="item._elementId + 'true'"
      >Yes</label>
      <label
      nz-radio 
      [nzValue]="false"
      (click)="checkIfDoubleSelection(false)"
      [(ngModel)]="booleanModels[1]" 
      [id]="item._elementId + 'false'"
      >No</label>
  </nz-radio-group>
</ng-container>
