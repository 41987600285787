import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  PUSH_SCORE_THRESHOLD,
  WoundMeasure,
} from './models/wound-measure.interface';

@Injectable({
  providedIn: 'root',
})
export class WoundMeasureService {
  private woundMeasure$ = new BehaviorSubject<WoundMeasure | null>(null);

  constructor() {
    window['WoundMeasureService'] = this;
  }

  getWoundMeasure(): Observable<WoundMeasure | null> {
    return this.woundMeasure$.asObservable().pipe(distinctUntilChanged());
  }

  setWoundMeasure(data: WoundMeasure): void {
    this.woundMeasure$.next(data);
  }

  areaScore(scoreThresholds: number[] = PUSH_SCORE_THRESHOLD): number {
    let result: number = -1;
    this.woundMeasure$.subscribe((woundMeasure) => {
      // Calculate the area of the wound
      let area = parseFloat(woundMeasure?.length.toFixed(1)) * parseFloat(woundMeasure?.width.toFixed(1));
      area = parseFloat(area.toFixed(1));

      if (area) {
        for (let i = 0; i < scoreThresholds.length; i++) {
          const threshold = scoreThresholds[i];
          if (i === 0) {
            if (area > threshold) {
              result = scoreThresholds.length - i;
              break;
            }
          } else {
            if (area >= threshold) {
              result = scoreThresholds.length - i;
              break;
            }
          }
        }
      }
    });

    return result;
  }
}
