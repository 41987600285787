import { Component, Input } from '@angular/core';
import { ItemChoiceType } from '../../lib/models/itemChoiceType.enum';

@Component({
  selector: 'lhc-item-choice',
  templateUrl: './lhc-item-choice.component.html',
  styleUrls: ['./lhc-item-choice.component.css'],
})
export class LhcItemChoiceComponent {
  @Input() item;
  itemChoiceType = ItemChoiceType;
  maxRadioButtonItems: number = 5;
  type = ItemChoiceType.AUTO_COMPLETE;

  getItemType(): string {
    if (this.item?.answers?.length <= this.maxRadioButtonItems) {
      if (this.item._multipleAnswers) {
        this.type = ItemChoiceType.CHECK_BOX;
      } else {
        this.type = ItemChoiceType.RADIO_BUTTON;
      }
    }
    return this.type;
  }
}
