import {OnChanges, Component, Input } from '@angular/core';
import {LhcDataService} from '../../lib/lhc-data.service';

@Component({
  selector: 'lhc-item-boolean',
  templateUrl: './lhc-item-boolean.component.html',
  styleUrls: ['./lhc-item-boolean.component.css']
})
export class LhcItemBooleanComponent implements OnChanges {
  @Input() item: any;
  booleanModels:  boolean[] = new Array(3);

  constructor(public lhcDataService: LhcDataService) {}

  /**
   * Invokded when the properties change
   * @param changes changes.prop contains the old and the new value...
   */
   ngOnChanges(changes) {
    // changes.prop contains the old and the new value...
    // reset initial status
    this.setInitialValue();
  }

  /**
   * onModelChange handler
   * @param value the new value in model
   */
  onModelChange(value) {
    let prevValue = this.item.value;
    this.item.value = value;
    this.lhcDataService.onItemValueChange(this.item, this.item.value, prevValue)
  }


  /**
   * Set initial status
   */
  setInitialValue(): void {
    if (this.item) {
      setTimeout(() => {
        let booleanValue = this.item.value;
        if (booleanValue === true) {
          this.booleanModels[0] = true;
        }
        else if (booleanValue === false) {
          this.booleanModels[1] = true;
        }
        else if (booleanValue === undefined || booleanValue === null) {
          this.booleanModels[2] = true;
        }
      }, 0);
    }
  }

  checkIfDoubleSelection(value: boolean): void {
    if(this.item.value === value){
      this.onModelChange(null);
      this.clearRadioSelection();
    } else if(this.item.value === null){
      this.onModelChange(value);
    }
  }

  clearRadioSelection(): void {
    // We use timeout here because the browser handles radio buttons outside the normal event chain
    setTimeout(() => {
      this.booleanModels[0] = null;
      this.booleanModels[1] = null;
      this.booleanModels[2] = true;
    }, 0);
  }
}
