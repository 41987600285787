import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  Undermining,
} from './models/undermining.interface';

@Injectable({
  providedIn: 'root',
})
export class UnderminingService {
  private undermining$ = new BehaviorSubject<Undermining | null>(null);
  
  constructor() {
    window['UnderminingService'] = this;
  }

  getUndermining(): Observable<Undermining | null> {
    return this.undermining$.asObservable().pipe(distinctUntilChanged());
  }

  setUndermining(data: Undermining): void {
    this.undermining$.next(data);
  }

  getIsWoundClosed(): boolean {
    let result: boolean = false;
    this.undermining$.subscribe((underminingItem) => {
      if (underminingItem !== null) {
        result = underminingItem.isWoundClosed;
      }
    });

    return result;
  }

  getHasTrace(): boolean {
    let result: boolean = false;
    this.undermining$.subscribe((underminingItem) => {
      if (underminingItem !== null) {
        result = underminingItem.hasTrace;
      }
    });

    return result;
  }

  underminingScore(): number {
    let result: number = -1;
    this.undermining$.subscribe((underminingItem) => {
      if (underminingItem !== null) {
        let depth: number = 0.0;
        if (underminingItem.depthInMillimeter) {
          const tempDepth =  parseFloat(underminingItem.depthInMillimeter.toFixed(1))  * 0.1;
          depth = parseFloat(tempDepth.toFixed(1));
        }
        result = this.score(
          underminingItem.isWoundClosed,
          underminingItem.hasTunneling,
          underminingItem.hasTrace,
          depth,
          underminingItem.occupancy
        );
      }
    });

    return result;
  }

  private score(isWoundClosed: boolean, hasTunneling: boolean, hasTrace: boolean, depth: number, occupancy: number): number {
    let score: number = -1;
    if (isWoundClosed) {
      score = 0;
    } else if (!isWoundClosed && !hasTrace) {
      score = -1;
    } else if (hasTunneling) {
      score = 5;
    } else if (depth === 0.0) {
      score = 1;
    } else if (depth < 2) {
      score = 2;
    } else if (depth >= 2 && depth <= 4 && occupancy < 6) {
      score = 3;
    } else if (depth >= 2 && depth <= 4 && occupancy >= 6) {
      score = 4;
    } else if (depth > 4) {
      score = 5;
    } else if (!isWoundClosed && !hasTunneling && !hasTrace) {
      score = -1;
    }
    return score;
  }
}
