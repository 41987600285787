import { BehaviorSubject, Observable } from 'rxjs';
import { BodyLocation } from './models/bodyLocation.interface';
import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BodyLocationService {
  private bodyLocation$ = new BehaviorSubject<BodyLocation | null>(null);

  constructor() {
    window['BodyLocationService'] = this;
  }

  getBodyLocation(): Observable<BodyLocation | null> {
    return this.bodyLocation$.asObservable().pipe(distinctUntilChanged());
  }

  setBodyLocation(data: BodyLocation): void {
    this.bodyLocation$.next(data);
  }
}
