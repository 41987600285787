<div class="container">
    <div class="items-container">
        <div class="item title">Wound Care Provided </div>
        <div *ngIf="!commonUtils.isEditMode()" class="item link" (click)="goToSelectOrder()">View/Change Order</div>
        <div *ngIf="commonUtils.isEditMode()" class="item disabled">View/Change Order</div>
    </div>
    <textarea 
        [disabled]="item._readOnly || commonUtils.isEditMode()"
        [ngModel]="item.value"
        (ngModelChange)="onModelChange($event)"
    ></textarea>
    <sm-validate [item]="item" [value]="item.value" [customErrors]="customErrors"></sm-validate>
</div>