<ng-container *ngIf="item">
  <ng-container
    [ngSwitch]="item.displayControl && item.displayControl.answerLayout && item.displayControl.answerLayout.type">
    <ng-container [ngSwitch]="getItemType()">
      <ng-container *ngSwitchCase="itemChoiceType.RADIO_BUTTON">
        <lhc-item-choice-radio-button [item]="item" [acOptions]="item._autocompOptions"></lhc-item-choice-radio-button>
      </ng-container>
      <ng-container *ngSwitchCase="itemChoiceType.CHECK_BOX">
        <lhc-item-choice-check-box [item]="item" [acOptions]="item._autocompOptions"></lhc-item-choice-check-box>
      </ng-container>
      <ng-container *ngSwitchCase="itemChoiceType.AUTO_COMPLETE">
        <lhc-item-choice-autocomplete [item]="item" [acOptions]="item._autocompOptions"></lhc-item-choice-autocomplete>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>