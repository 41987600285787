<div class="container">
  <div class="items-container">
    <div class="item title">Body Location</div>
    <div *ngIf="!commonUtils.isEditMode()" class="item link" (click)="goToEditBodyMap()">Edit Body Map</div>
    <div *ngIf="commonUtils.isEditMode()" class="item disabled">Edit Body Map</div>
  </div>
  <div>
    <input type="text" readonly [value]="getPrimaryLocation()">
    <sm-validate [item]="item" [value]="item.value" ></sm-validate>
  </div>
</div>