import { BehaviorSubject, Observable } from 'rxjs';
import { SmartTissue } from './models/smartTissue.interface';
import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SmartTissueService {
  private smartTissue$ = new BehaviorSubject<SmartTissue | null>(null);

  constructor() {
    window['SmartTissueService'] = this;
  }

  getSmartTissue(): Observable<SmartTissue | null> {
    return this.smartTissue$.asObservable().pipe(distinctUntilChanged());
  }

  setSmartTissue(data: SmartTissue): void {
    this.smartTissue$.next(data);
  }
}
