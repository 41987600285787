<ng-container *ngIf="item">
  <nz-date-picker #nzDatePickerComponent
    [nzDisabled]="item._readOnly"
    id="{{item._elementId}}"
    class="lhc-datetime-picker"
    nzShowTime
    nzFormat="MMM dd, yyyy HH:mm:ss"
    nzPlaceHolder="{{item._placeholder}}"
    nzSize="small"
    (ngModelChange)="onModelChange($event)"
    [(ngModel)]="item.value"
    (nzOnOk)="onOk($event)"
    (focus)="lhcDataService.setActiveRow(item)"
    attr.aria-required="{{item._answerRequired}}"
  ></nz-date-picker>
</ng-container>
