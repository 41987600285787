import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class PrePopulateService {
  constructor() {
    window['PrePopulateService'] = this;
  }

  // Pre-populate questionnaire with data from DataEntryPoints
  prePopulateQuestionnaire(formWithData: any, dataEntryPoints: any): any {
    for (var key in dataEntryPoints) {
      var value = dataEntryPoints[key];
      // Call the function to find and set the value into the JSON
      this.findValueCodeForQuestionCode(formWithData?.items, key, value?.code);
    }
    return formWithData;
  }

  private findValueCodeForQuestionCode(
    items: any[],
    questionCode: string,
    newValue: any
  ): string {
    for (const item of items) {
      if (item.questionCode === questionCode) {
        if (
          item.dataType === 'SMART-TISSUE' ||
          item.dataType === 'BODY-PICKER' ||
          item.dataType === 'SECTION' ||
          item.dataType === 'QTY'
        ) {
          item.value = null;
        } else if (item.dataType === 'BL') {
          try {
            item.value = JSON.parse(newValue);
          } catch (error) {
            item.value = null;
          }
        } else if (item.dataType === 'CNE' || item.dataType === 'CWE') {
          let option: any;
          try {
            const arrayOfValues = JSON.parse(newValue);
            option = [];
            arrayOfValues.forEach((valueItem) => {
              const tempOption = item.answers.find(
                (answer) => answer.code === valueItem
              );
              if (tempOption) {
                option.push(tempOption);
              }
            });
          } catch (e) {
            option = item.answers.find((answer) => answer.code === newValue);
          }
          item.value = option;
        } else {
          item.value = newValue;
        }
        return item.value;
      }
      if (item.items && item.items.length > 0) {
        const value = this.findValueCodeForQuestionCode(
          item.items,
          questionCode,
          newValue
        ); // Recursive call to review items inside an item
        if (value) {
          return value;
        }
      }
    }
    return ''; // Return empty string if value is not found
  }
}
