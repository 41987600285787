export interface WoundMeasure {
  width: number;
  length: number;
}

export const WOUND_TYPE_TO_CALCULATE_PUSH_SCORE = {
  pressure: 'pressure',
};

export const QUESTION_CODE = {
  // PUSH
  exudateAmount: 'exudateAmount',
  woundType: 'woundType',
  tissueEschar: 'tissueEschar',
  tissueSlough: 'tissueSlough',
  escharPercentage: 'tissueEscharAmount',
  sloughPercentage: 'tissueSloughAmount',
  granulationPercentage: 'tissueGranulationAmount',
  epithelialPercentage: 'tissueEpithelialAmount',
  epithelialExists: 'epithelialExists',
  granulationExists: 'granulationExists',
  sloughExists: 'sloughExists',
  escharExists: 'escharExists',
  // BWAT
  tissueDepth: 'tissueDepth',
  woundMargins: 'woundMargins', // Edges
  exudateType: 'exudateType',
  skinColor: 'skinColor',
  edema: 'edema',
  induration: 'induration',
  // Cognitive Impaired Pain
  cognitivelyImpaired: 'cognitivelyImpaired',
  ciBreathing: 'ciBreathing', // Breathing (ind. of vocalization)
  ciVocalization: 'ciVocalization', // Negative vocalization
  ciFacial: 'ciFacial', // Facial expression
  ciBodyLanguage: 'ciBodyLanguage', // Body language
  ciConsolability: 'ciConsolability', // Consolability
  surroundingPainFreq: 'surroundingPainFreq',
  // Wound Date
  woundDate: 'woundDate',
  // Custom Components
  bodyPicker: 'bodyPicker',
  smartTissue: 'smartTissue',
  woundCareOrderPicker: 'woundCareOrderPicker',
  // Custom label for Scoring Type
  scoringType: 'scoringType',
};

export const ANSWER_CODE = {
  none: 'none',
  light: 'light',
  moderate: 'moderate',
  heavy: 'heavy',
};

export const TISSUE_SLOUGH_RANGES = {
  tissueSlough100: 'tissueSlough100',
  tissueSlough75To100: 'tissueSlough75To100',
  tissueSlough50To75: 'tissueSlough50To75',
  tissueSlough25To50: 'tissueSlough25To50',
  tissueSlough0To25: 'tissueSlough0To25',
  tissueSlough0: 'tissueSlough0',
};

export const TISSUE_ESCHAR_RANGES = {
  tissueEschar100: 'tissueEschar100',
  tissueEschar75To100: 'tissueEschar75To100',
  tissueEschar50To75: 'tissueEschar50To75',
  tissueEschar25To50: 'tissueEschar25To50',
  tissueEschar0To25: 'tissueEschar0To25',
  tissueEschar0: 'tissueEschar0',
};

export const ANSWERS_CODES_TISSUE_SLOUGH_RANGES = {
  none: 'none',
  whiteNonAdherentYellow: 'whiteNonAdherentYellow',
  looseAdherentYellow: 'looseAdherentYellow',
}

export const ANSWERS_CODES_TISSUE_ESCHAR_RANGES = {
  none: 'none',
  tissueEscharAdherantSoftBlack: 'tissueEscharAdherantSoftBlack',
  tissueEscharAdherantFirmBlack: 'tissueEscharAdherantFirmBlack',
}

export const TISSUE_ESCHAR_PERCENTAGES = {
  tissueEschar0: 0,
  tissueEschar10: 10,
  tissueEschar20: 20,
  tissueEschar30: 30,
  tissueEschar40: 40,
  tissueEschar50: 50,
  tissueEschar60: 60,
  tissueEschar70: 70,
  tissueEschar80: 80,
  tissueEschar90: 90,
  tissueEschar100: 100,
};

export const TISSUE_SLOUGH_PERCENTAGES = {
  tissueSlough0: 0,
  tissueSlough10: 10,
  tissueSlough20: 20,
  tissueSlough30: 30,
  tissueSlough40: 40,
  tissueSlough50: 50,
  tissueSlough60: 60,
  tissueSlough70: 70,
  tissueSlough80: 80,
  tissueSlough90: 90,
  tissueSlough100: 100,
};

export const TISSUE_GRANULATION_PERCENTAGES = {
  tissueGranulation0: 0,
  tissueGranulation10: 10,
  tissueGranulation20: 20,
  tissueGranulation30: 30,
  tissueGranulation40: 40,
  tissueGranulation50: 50,
  tissueGranulation60: 60,
  tissueGranulation70: 70,
  tissueGranulation80: 80,
  tissueGranulation90: 90,
  tissueGranulation100: 100,
};

export const TISSUE_EPITHELIAL_PERCENTAGES = {
  tissueEpithelial0: 0,
  tissueEpithelial10: 10,
  tissueEpithelial20: 20,
  tissueEpithelial30: 30,
  tissueEpithelial40: 40,
  tissueEpithelial50: 50,
  tissueEpithelial60: 60,
  tissueEpithelial70: 70,
  tissueEpithelial80: 80,
  tissueEpithelial90: 90,
  tissueEpithelial100: 100,
};

// Scores for map area score, are in reverse order, i.e. the first element is the highest score
// PUSH Score Thresholds
export const PUSH_SCORE_THRESHOLD: number[] = [
  24, 12.1, 8.1, 4.1, 3.1, 2.1, 1.1, 0.7, 0.3, 0,
];

// BWAT Score Thresholds
export const BWAT_SCORE_THRESHOLD: number[] = [80, 36.1, 16.1, 4, 0];

export const TISSUE_DEPTH_VALUES_SCORE = {
  tissueDepthIntact: 1,
  tissueDepthPartialThickness: 2,
  tissueDepthFullThickness: 3,
  tissueDepthObscured: 4,
  tissueDepthFullWithDamage: 5,
};

export const TISSUE_EDGES_VALUES_SCORE = {
  indistinct: 1,
  distinct: 2,
  nonAttached: 3,
  rolledEdge: 4,
  fibrotic: 5,
};

export const EXUDATE_TYPE_VALUES_SCORE = {
  none: 1,
  sanguinous: 2, //Bloody
  serosanguinous: 3,
  serous: 4,
  purulent: 5,
}

export const EXUDATE_AMOUNT_VALUES_SCORE = {
  none: 1,
  scant: 2,
  light: 3,
  moderate: 4,
  large: 5,
}

export const SKIN_COLOR_VALUES_SCORE = {
  normalColor: 1,
  erythema: 2,
  atropheBlanche: 3,
  haemosiderin: 4,
  hyperpigment: 5,
}

export const PERIPHERAL_EDEMA_VALUES_SCORE = {
  none: 1,
  underFour: 2,
  overFour: 3,
  pittingUnderFour: 4,
  pittingOverFour: 5,
}

export const PERIPHERAL_TISSUE_INDURATION_VALUES_SCORE = {
  none: 1,
  underTwo: 2,
  twoToFourUnder50: 3,
  twoToFourOver50: 4,
  overFour: 5,
}

export const GRANULATION_TISSUE_VALUES_SCORE = {
  tissueGranulationIntact: 1,
  tissueGranulation75To100: 2,
  tissueGranulation25To75: 3,
  tissueGranulation0To25: 4,
  tissueGranulation0: 5,
  tissueGranulation10: 4,
  tissueGranulation20: 4,
  tissueGranulation30: 3,
  tissueGranulation40: 3,
  tissueGranulation50: 3,
  tissueGranulation60: 3,
  tissueGranulation70: 3,
  tissueGranulation80: 2,
  tissueGranulation90: 2,
  tissueGranulation100: 2,
}

export const EPITHELIALIZATION_TISSUE_VALUES_SCORE = {
  tissueEpithelial100: 1,
  tissueEpithelial75To100: 2,
  tissueEpithelial50To75: 3,
  tissueEpithelial25To50: 4,
  tissueEpithelial0To25: 5,
  tissueEpithelial0: 0,
  tissueEpithelial10: 5,
  tissueEpithelial20: 5,
  tissueEpithelial30: 4,
  tissueEpithelial40: 4,
  tissueEpithelial50: 4,
  tissueEpithelial60: 3,
  tissueEpithelial70: 3,
  tissueEpithelial80: 2,
  tissueEpithelial90: 2,
}

export const CI_BREATHING_VALUES_SCORE = {
  ciBreathing0: 0,
  ciBreathing1: 1, 
  ciBreathing2: 2,
}

export const CI_VOCALIZATION_VALUES_SCORE = {
  ciVocalization0: 0,
  ciVocalization1: 1, 
  ciVocalization2: 2,
}

export const CI_FACIAL_VALUES_SCORE = {
  ciFacial0: 0,
  ciFacial1: 1, 
  ciFacial2: 2,
}

export const CI_BODY_LANGUAGE_VALUES_SCORE = {
  ciBodyLanguage0: 0,
  ciBodyLanguage1: 1, 
  ciBodyLanguage2: 2,
}

export const CI_CONSOLABILITY_VALUES_SCORE = {
  ciConsolability0: 0,
  ciConsolability1: 1, 
  ciConsolability2: 2,
}

export interface ExistAndScore {
  exist: boolean | null;
  score: number;
}
