import { Component, Input, OnChanges } from '@angular/core';
import { LhcDataService } from '../../lib/lhc-data.service';

@Component({
  selector: 'sm-answer-help-text',
  templateUrl: './sm-answer-help-text.component.html',
  styleUrls: ['./sm-answer-help-text.component.css'],
})
export class SmAnswerHelpTextComponent implements OnChanges {
  @Input() item: any;
  @Input() text: string;
  helpText: string = '';
  showHelpText: boolean = false;

  constructor(private lhcDataService: LhcDataService) {}

  /**
   * Invoked when the properties change
   * @param changes changes.prop contains the old and the new value...
   */
  ngOnChanges(changes) {
    if (changes.item) {
      if (this.item.helpText) {
        this.helpText = this.item.helpText;
        this.showHelpText = true;
      }
    }
  }
}
