<div *ngIf="item">
  <lhc-autocomplete 
    [options]="options" 
    [item]="item"
    [(dataModel)]="item.value"
    (onBlurFn)="lhcDataService.activeRowOnBlur(item)"
    (onFocusFn)="lhcDataService.setActiveRow(item)"
    [isFormReady]="lhcDataService.isFormReady()"
    [nzDisabled]="commonUtils.isEditMode()"
  ></lhc-autocomplete>
</div>
