import { Component, Input, OnInit } from '@angular/core';
import { BodyLocation } from '../../lib/models/bodyLocation.interface';
import { LhcDataService } from '../../lib/lhc-data.service';
import { BodyLocationService } from '../../lib/body-location.service';
import { CommonUtilsService } from '../../lib/common-utils.service';
import { QUESTION_CODE } from 'src/lib/models/wound-measure.interface';

declare function sendMessageToFlutter(event, item): void;
@Component({
  selector: 'sm-body-location',
  templateUrl: './sm-body-location.component.html',
  styleUrls: ['./sm-body-location.component.css'],
})
export class SmBodyLocationComponent implements OnInit {
  @Input() bodyLocation: BodyLocation;
  @Input() item: any;
  bodyLocationQuestionCode = QUESTION_CODE.bodyPicker;

  constructor(
    private lhcDataService: LhcDataService,
    private bodyLocationService: BodyLocationService,
    public commonUtils: CommonUtilsService
  ) {}

  ngOnInit() {
    this.bodyLocationService.getBodyLocation().subscribe((value) => {
      this.bodyLocation = value;
      this.setBodyLocationValue();
    });
  }

  /**
   * Get the body location description, separated with commas if both inputs are filled (location and orientation)
   * @returns a string with the body location description, comma separated if both inputs are filled
   */
  getPrimaryLocation(): string {
    if (this.bodyLocation?.location && this.bodyLocation?.orientation) {
      return `${this.bodyLocation?.location}, ${this.bodyLocation?.orientation}`;
    } else {
      return `${this.bodyLocation?.location ?? ''}${
        this.bodyLocation?.orientation ?? ''
      }`;
    }
  }

  goToEditBodyMap(): void {
    sendMessageToFlutter('bodyLocationChange', null);
  }

  private setBodyLocationValue(): void {
    let formWithData = this.lhcDataService.getLhcFormData();
    this.findForQuestionCodeAndSetValue(
      formWithData.items,
      QUESTION_CODE.bodyPicker
    );
    setTimeout(() => {
      this.lhcDataService.setLhcFormData(formWithData);
    });
  }

  private findForQuestionCodeAndSetValue(
    items: any[],
    questionCode: string
  ): string {
    const newValue = this.getPrimaryLocation();
    for (const item of items) {
      if (item.questionCode === questionCode) {
        item.value = newValue;
        return item.value?.code ?? item.value;
      }
      if (item.items && item.items.length > 0) {
        const value = this.findForQuestionCodeAndSetValue(
          item.items,
          questionCode
        ); // Recursive call to review items inside an item
        if (value) {
          return value;
        }
      }
    }
    return ''; // Return empty string if value is not found
  }
}
