import { Injectable } from '@angular/core';
import {
  CI_BODY_LANGUAGE_VALUES_SCORE,
  CI_BREATHING_VALUES_SCORE,
  CI_CONSOLABILITY_VALUES_SCORE,
  CI_FACIAL_VALUES_SCORE,
  CI_VOCALIZATION_VALUES_SCORE,
  QUESTION_CODE,
} from './models/wound-measure.interface';

@Injectable({
  providedIn: 'root',
})
export class CognitivelyImpairedPainService {
  public cognitivelyImpairedPainADScore: number | null = null;
  public isHidden: boolean = false;
  private jsonQuestionnaire: any = null;

  constructor() {
    window['CognitivelyImpairedPainService'] = this;
  }

  setQuestionnaireResponse(data: any): void {
    this.jsonQuestionnaire = data;
    if (
      this.calculateCognitivelyImpaired() !==
      this.cognitivelyImpairedPainADScore
    ) {
      this.cognitivelyImpairedPainADScore = this.calculateCognitivelyImpaired();
    }
  }

  private calculateCognitivelyImpaired(): number | null {
    let score: number = -1;
    if (this.jsonQuestionnaire?.items) {
      const checkExistCognitivelyImpaired =
        this.ScoreWhenIsABooleanValueForQuestionCode(
          this.jsonQuestionnaire.items,
          QUESTION_CODE.cognitivelyImpaired
        );
  
      switch (checkExistCognitivelyImpaired) {
        case 1:
          this.isHidden = false;
          return this.calculateCognitivelyImpairedScore();
        case null:
          score = -1;
          this.isHidden = true;
          break;
        case -1:
          this.isHidden = false;
          score = -1;
          break;
      }
      return score;
    } else {
      return null;
    }
  }

  private calculateCognitivelyImpairedScore(): number {
    let result: number = -1;
    const ciBreathing = this.calculateScoreFor(QUESTION_CODE.ciBreathing);
    const ciVocalization = this.calculateScoreFor(QUESTION_CODE.ciVocalization);
    const ciFacial = this.calculateScoreFor(QUESTION_CODE.ciFacial);
    const ciBodyLanguage = this.calculateScoreFor(QUESTION_CODE.ciBodyLanguage);
    const ciConsolability = this.calculateScoreFor(
      QUESTION_CODE.ciConsolability
    );

    if (
      ciBreathing !== -1 &&
      ciVocalization !== -1 &&
      ciFacial !== -1 &&
      ciBodyLanguage !== -1 &&
      ciConsolability !== -1
    ) {
      result =
        ciBreathing +
        ciVocalization +
        ciFacial +
        ciBodyLanguage +
        ciConsolability;
    } else {
      result = -1;
    }
    return result;
  }

  private ScoreWhenIsABooleanValueForQuestionCode(
    items: any[],
    questionCode: string
  ): number | null {
    for (const item of items) {
      if (item.questionCode === questionCode) {
        if (item.value === true) {
          return 1;
        } else if (item.value === false) {
          return 0;
        } else if (item.value === undefined) {
          return -1;
        }
      }
      if (item.items && item.items.length > 0) {
        const value = this.ScoreWhenIsABooleanValueForQuestionCode(
          item.items,
          questionCode
        ); // Recursive call to review items inside an item
        if (value) {
          return value;
        }
      }
    }
    return null; // Return empty string if value is not found
  }

  private findValueCodeForQuestionCode(
    items: any[],
    questionCode: string
  ): string {
    for (const item of items) {
      if (item.questionCode === questionCode) {
        return item.value?.code ?? item.value;
      }
      if (item.items && item.items.length > 0) {
        const value = this.findValueCodeForQuestionCode(
          item.items,
          questionCode
        ); // Recursive call to review items inside an item
        if (value) {
          return value;
        }
      }
    }
    return ''; // Return empty string if value is not found
  }

  private calculateScoreFor(questionCode: string): number {
    let result: number = -1;
    const valueForQuestionCode = this.findValueCodeForQuestionCode(
      this.jsonQuestionnaire.items,
      questionCode
    );

    if (!valueForQuestionCode) {
      result = -1;
    } else {
      switch (questionCode) {
        case QUESTION_CODE.ciBreathing:
          return CI_BREATHING_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.ciVocalization:
          return CI_VOCALIZATION_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.ciFacial:
          return CI_FACIAL_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.ciBodyLanguage:
          return CI_BODY_LANGUAGE_VALUES_SCORE[valueForQuestionCode];
        case QUESTION_CODE.ciConsolability:
          return CI_CONSOLABILITY_VALUES_SCORE[valueForQuestionCode];
      }
    }
    return result;
  }
}
