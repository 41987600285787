import { Component, Input, OnInit } from '@angular/core';
import { CognitivelyImpairedPainService } from '../../lib/cognitively-impaired-pain.service';
import { CommonUtilsService } from '../../lib/common-utils.service';
import { LhcDataService } from '../../lib/lhc-data.service';
import { QUESTION_CODE } from '../../lib/models/wound-measure.interface';
import { Observable } from 'rxjs';
import { QuestionnaireData } from 'src/lib/models/questionnaire.interface';
import { QuestionnaireDataService } from 'src/lib/questionnaire-data.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'sm-item',
  templateUrl: './sm-item.component.html',
  styleUrls: ['./sm-item.component.css'],
})
export class SmItemComponent {
  @Input() item;
  public questionCode = QUESTION_CODE;

  // This variable is used to get the Scoring Types from QuestionnaireDataService
  public questionnaireDataService$: Observable<QuestionnaireData | null> =
    this.questionnaireDataService.getQuestionnaireData();

  public scoringTypes$: Observable<string> =
    this.questionnaireDataService$.pipe(
      map((data) => data?.scoringTypes.join(', '))
    );
    
  constructor(
    public lhcDataService: LhcDataService,
    public commonUtilsService: CommonUtilsService,
    public cognitivelyImpairedPainService: CognitivelyImpairedPainService,
    private questionnaireDataService: QuestionnaireDataService
  ) { }

  getStringify(jsonToStingify: string) {
    return JSON.stringify(jsonToStingify);
  }

  isArray(obj: any): boolean {
    return Array.isArray(obj);
  }
}
