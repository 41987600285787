import { Injectable } from '@angular/core';
import { WoundMeasureService } from './wound-measure.service';
import {
  ANSWER_CODE,
  ExistAndScore,
  QUESTION_CODE,
  TISSUE_EPITHELIAL_PERCENTAGES,
  TISSUE_ESCHAR_PERCENTAGES,
  TISSUE_GRANULATION_PERCENTAGES,
  TISSUE_SLOUGH_PERCENTAGES,
  WOUND_TYPE_TO_CALCULATE_PUSH_SCORE,
} from './models/wound-measure.interface';

declare function sendMessageToFlutter(event, item): void;

@Injectable({
  providedIn: 'root',
})
export class PushScoreService {
  private pushScore: number | null = null;
  private jsonQuestionnaire: any = null;
  private woundType: string = '';

  constructor(private woundMeasureService: WoundMeasureService) {
    window['PushScoreService'] = this;
  }

  setQuestionnaireResponse(data: any): void {
    this.jsonQuestionnaire = data;
    if (this.calculatePushScore() !== this.pushScore) {
      this.pushScore = this.calculatePushScore();
      console.log('Push Score Changed: ', this.pushScore);
      // Send message to flutter through the channel on JS side on questionnaire.html
      sendMessageToFlutter('pushScoreChanged', { pushScore: this.pushScore });
    }
  }

  private startsWithPressure(input: string): boolean {
    // Only check if the first word is pressure, because PUSH calculation is for pressure wounds only
    return input
      ?.toLowerCase()
      .startsWith(WOUND_TYPE_TO_CALCULATE_PUSH_SCORE.pressure);
  }

  //Make calculation for push score here
  /// Re-calculated PUSH score from area/exudate/tissue — only returns null if not a pressure wound
  private calculatePushScore(): number | null {
    if (this.jsonQuestionnaire !== null) {
      this.woundType = this.findValueCodeForQuestionCode(
        this.jsonQuestionnaire.items,
        QUESTION_CODE.woundType
      );

      if (!this.startsWithPressure(this.woundType)) {
        return null; // Only null if wound Type is not a pressure wound
      } else {
        if (this.woundMeasureService.areaScore() === -1) {
          return -1;
        } else {
          const areaScore = this.woundMeasureService.areaScore();
          const exudateScore = this.calculateExudateScore();
          const tissueScore = this.calculateTissueScore();

          let totalScore: number = -1;
          if (areaScore === 0) {
            // PUSH Score is always 0 if areaScore is 0
            return 0;
          } else if (
            areaScore !== -1 &&
            exudateScore !== -1 &&
            tissueScore !== -1
          ) {
            totalScore = areaScore + exudateScore + tissueScore;
          } else {
            totalScore = -1;
          }
          return totalScore;
        }
      }
    }
  }

  private findValueCodeForQuestionCode(
    items: any[],
    questionCode: string
  ): string {
    for (const item of items) {
      if (item.questionCode === questionCode) {
        return item.value?.code;
      }
      if (item.items && item.items.length > 0) {
        const value = this.findValueCodeForQuestionCode(
          item.items,
          questionCode
        ); // Recursive call to review items inside an item
        if (value) {
          return value;
        }
      }
    }
    return ''; // Return empty string if value is not found
  }

  private ScoreWhenIsABooleanValueForQuestionCode(
    items: any[],
    questionCode: string
  ): number | null {
    for (const item of items) {
      if (item.questionCode === questionCode) {
        if (item.value === true) {
          return 1;
        } else if (item.value === false) {
          return 0;
        } else if (item.value === undefined) {
          return -1;
        }
      }
      if (item.items && item.items.length > 0) {
        const value = this.ScoreWhenIsABooleanValueForQuestionCode(
          item.items,
          questionCode
        ); // Recursive call to review items inside an item
        if (value) {
          return value;
        }
      }
    }
    return null; // Return empty string if value is not found
  }

  private calculateExudateScore(): number {
    let result: number;
    const exudateAmount = this.findValueCodeForQuestionCode(
      this.jsonQuestionnaire.items,
      QUESTION_CODE.exudateAmount
    );

    if (!exudateAmount) {
      result = -1;
    } else {
      switch (exudateAmount) {
        case ANSWER_CODE.none:
          result = 0;
          break;
        case ANSWER_CODE.light:
          result = 1;
          break;
        case ANSWER_CODE.moderate:
          result = 2;
          break;
        case ANSWER_CODE.heavy:
          result = 3;
          break;
        default:
          result = -1;
      }
    }
    return result;
  }

  private calculateTissueScore(): number {
    let result: number = -1;

    const escharValues = this.ExistsForQuestionCode(QUESTION_CODE.escharExists, QUESTION_CODE.escharPercentage);
    const sloughValues = this.ExistsForQuestionCode(QUESTION_CODE.sloughExists, QUESTION_CODE.sloughPercentage);
    const granulationValues = this.ExistsForQuestionCode(QUESTION_CODE.granulationExists, QUESTION_CODE.granulationPercentage);
    const epithelialValues = this.ExistsForQuestionCode(QUESTION_CODE.epithelialExists, QUESTION_CODE.epithelialPercentage);
    
    if (escharValues.score > 0) {
      result = escharValues.score;
    } else if (sloughValues.score > 0) {
      result = sloughValues.score;
    } else if (granulationValues.score > 0) {
      result = granulationValues.score;
    } else if (epithelialValues.score > 0) {
      result = epithelialValues.score;
    } else if (
      escharValues.score === 0 ||
      sloughValues.score === 0 ||
      granulationValues.score === 0 ||
      epithelialValues.score === 0
    ) {
      result = 0;
    } else if (
      escharValues.score === -1 &&
      sloughValues.score === -1 &&
      granulationValues.score === -1 &&
      epithelialValues.score === -1
    ) {
      result = -1;
    }
    return result;
  }

  private ExistsForQuestionCode(questionCodeExist: string, questionCodePercentage: string): ExistAndScore {
    let exist: boolean | null = null;
    let score: number = -1;
    const checkExist = this.ScoreWhenIsABooleanValueForQuestionCode(
      this.jsonQuestionnaire.items,
      questionCodeExist
    );
    
    switch (checkExist) {
      case 1:
        exist = true;
        score = this.findScoreForTissuePercentage(
          questionCodePercentage
        );
        break;
      case null:
        exist = false;
        score = 0;
        break;
      case -1:
        exist = false;
        score = -1;
        break;
    }
    return { exist, score };
  }

  private findScoreForTissuePercentage(typeOfTissue: string): number {
    let percentage: string = undefined;
    let result: number = -1;
    percentage = this.findValueCodeForQuestionCode(
      this.jsonQuestionnaire.items,
      typeOfTissue
    );

    if (!percentage) {
      result = -1;
    } else if (percentage === ANSWER_CODE.none) {
      result = 0;
    } else {
      if (typeOfTissue === QUESTION_CODE.escharPercentage) {
        TISSUE_ESCHAR_PERCENTAGES[percentage] > 0 ? (result = 4) : (result = 0);
      } else if (typeOfTissue === QUESTION_CODE.sloughPercentage) {
        TISSUE_SLOUGH_PERCENTAGES[percentage] > 0 ? (result = 3) : (result = 0);
      } else if (typeOfTissue === QUESTION_CODE.granulationPercentage) {
        TISSUE_GRANULATION_PERCENTAGES[percentage] > 0
          ? (result = 2)
          : (result = 0);
      } else if (typeOfTissue === QUESTION_CODE.epithelialPercentage) {
        TISSUE_EPITHELIAL_PERCENTAGES[percentage] > 0
          ? (result = 1)
          : (result = 0);
      }
    }
    return result;
  }
}
