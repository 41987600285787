import { Component, Input, OnInit } from '@angular/core';
import { WoundCareOrder } from '../../lib/models/woundCareOrder.interface';
import { WoundCareOrderService } from '../../lib/wound-care-order.service';
import { LhcDataService } from '../../lib/lhc-data.service';
import { CommonUtilsService } from '../../lib/common-utils.service';
import { QUESTION_CODE } from 'src/lib/models/wound-measure.interface';

declare function sendMessageToFlutter(event, item): void;
@Component({
  selector: 'sm-wound-care-order-picker',
  templateUrl: './sm-wound-care-order-picker.component.html',
  styleUrls: ['./sm-wound-care-order-picker.component.css'],
})
export class SmWoundCareOrderPickerComponent implements OnInit {
  @Input() woundCareOrder: WoundCareOrder;
  @Input() item: any;
  woundCareOrderQuestionCode = QUESTION_CODE.woundCareOrderPicker;
  private isFirstPass: boolean = true;
  public customErrors: { errorMessage: string }[];

  constructor(
    private lhcDataService: LhcDataService,
    private woundCareOrderService: WoundCareOrderService,
    public commonUtils: CommonUtilsService
  ) { }

  ngOnInit() {
    this.woundCareOrderService.getOrder().subscribe((value) => {
      this.woundCareOrder = value;
      if (!this.isFirstPass) {
        this.onModelChange(value?.description);
      } else {
        if (!this.item.value) {
          this.onModelChange(value?.description);
        } else {
          this.onModelChange(this.item.value);
        }
        this.isFirstPass = false;
      }
    });
  }

  goToSelectOrder(): void {
    sendMessageToFlutter('selectOrder', null);
  }

  onModelChange(value) {
    let prevValue = this.item.value;
    this.item.value = value;
    if (value?.trim() === this.woundCareOrder?.description?.trim()) {
      this.woundCareOrderService.setWasWoundCareOrderModified(false);
      this.customErrors = [{ errorMessage: 'must be edited' }];
    } else {
      this.woundCareOrderService.setWasWoundCareOrderModified(true);
      this.customErrors = undefined;
    }
    this.lhcDataService.onItemValueChange(this.item, this.item.value, prevValue);

  }
}
