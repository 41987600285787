<div *ngIf="item">
  <nz-radio-group [ngModel]="radioValue" (ngModelChange)="onRadioModelChange($event)" [nzDisabled]="item._readOnly || commonUtils.isEditMode()"
    [nzName]="radiogroup + item._elementId" attr.aria-labelledby="label-{{ item._elementId }}" role="radiogroup" nz-row
    class="lhc-radio-group"
    [ngClass]="{'lhc-vertical': commonUtils.getDisplayControlIsVertical(item.displayControl) || showRadioButtonsVertical}">
    <label *ngFor="let answer of acOptions.listItems; index as i" class="lhc-answer" nz-radio
      (click)="lhcDataService.setActiveRow(item); checkIfDoubleSelection(item.answers[i])" [nzValue]="item.answers[i]" [(ngModel)]="radioModels[i]"
      [id]="lhcDataService.getItemAnswerId(item, answer)">
      {{ commonUtils.getDisplayText(answer._displayText) }}<sm-answer-help-text [item]="item.answers[i]"
        [text]="commonUtils.getHelpText(answer._displayText)"></sm-answer-help-text>
    </label>

    <label *ngIf="item.dataType === 'CWE'" nz-radio (click)="lhcDataService.setActiveRow(item); checkIfDoubleSelectionOnOther($event)"
      [nzValue]="{'text': otherValue, '_notOnList': true}" [(ngModel)]="otherRadioModel"
      [id]="item._elementId + '_other'" class="lhc-answer">
      Other
      <input type="text" [ngModel]="otherValue" (ngModelChange)="onOtherValueChange($event)"
        class="lhc-answer-other-input" [id]="item._elementId + '_otherValue'" nz-input [disabled]="item._readOnly"
        *ngIf="radioValue && radioValue._notOnList" (focus)="lhcDataService.setActiveRow(item)" />
    </label>
  </nz-radio-group>

</div>