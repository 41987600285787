<ng-container *ngIf="item && displayErrors()">  
  <div class *ngIf="item._hasValidation">
    <div *ngFor="let error of item._validationErrors; index as i">
      <div class="validation-error">
        <i class="lhc-error-icon" nz-icon nzType="close"></i>
        <span class="lhc-error-item">{{item.question}}</span>
        <span class="lhc-error-msg">{{error}}</span>
      </div>
    </div>
    <div class="validation-error custom-errors" *ngFor="let error of customErrors; index as i">
      <i class="lhc-error-icon" nz-icon nzType="close"></i>
      <span class="lhc-error-item">{{item.question}}</span>
      <span class="lhc-error-msg">{{error.errorMessage}}</span>
    </div>
  </div> 
  <div class="errors-engine-container" *ngIf="errorsData && errorsData.length > 0">
    <span *ngFor="let errorData of errorsData" class="errors-engine-description"><strong>Inconsistent Response:</strong> {{errorData.error}}</span>
  </div> 
  <!-- hidden item.value -->
  <div class="lhc-watched-value">{{value}}</div>
</ng-container>
