import { Injectable } from '@angular/core';

declare function isSummaryView(): boolean;

@Injectable({
  providedIn: 'root',
})
export class SmService {
  
  constructor() {
    window['SmService'] = this;
  }

  getIsEvaluationSummaryView() {
    try {
      return isSummaryView();
    } catch {
      return false;
    }
  }
}
