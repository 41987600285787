import { Injectable } from '@angular/core';
import { QUESTION_CODE } from './models/wound-measure.interface';

import CommonUtils from './lforms/lhc-common-utils.js';

declare function isFormOnEditMode(): boolean;
@Injectable({
  providedIn: 'root',
})
export class CommonUtilsService {

  // Declare the QUESTION_CODE object in the global scope so it can be used in the questionnaire and summary html files
  constructor() { 
    window['QUESTION_CODE'] = QUESTION_CODE;
  }

  /**
   * Check if two answers can be treated as same
   * @param answer an answer item that could have part of the attributes set
   * @param completeAnswer an answer in the answer list that usually has more attributes set
   * @param item the lforms item that has the completeAnswer in the answer list
   * @private
   */
  areTwoAnswersSame(answer: any, completeAnswer: any, item: any): boolean {
    return CommonUtils.areTwoAnswersSame(answer, completeAnswer, item);
  }

  /**
   * Convert a string to a number
   * @param value string value
   * @param dataType the data type of the converted value. 'integer' or 'decimal'
   */
  str2num(value, dataType) {
    let newValue: number = null;
    if (dataType === 'integer') {
      let intValue = parseInt(value);
      newValue = isNaN(intValue) ? null : intValue;
    } else if (dataType === 'decimal') {
      let floatValue = parseFloat(value);
      newValue = isNaN(floatValue) ? null : floatValue;
    }

    return newValue;
  }

  /**
   * Check if a checkbox or radio button control should use vertical layout.
   * Returns true if it should use vertical layout, false if horizontal.
   * @param displayControl an object that controls the display of the selected template
   */
  getDisplayControlIsVertical(displayControl) {
    return (
      displayControl?.answerLayout?.columns !== undefined &&
      displayControl.answerLayout.columns !== '0'
    );
  }

  /**
   * Get the aria-label for a control
   */
  getAriaLabel(item) {
    return item.prefix ? `${item.prefix} ${item.question}` : item.question;
  }

  /**
   * Checks if the itemCode is a value that can must unique in a multiselect (any other value can be selected)
   * @param itemCode code for the question to compare
   * @returns null if the itemCode does not correspond to a unique value, or the object that must be used if it's unique
   */
  isUniqueValue(itemCode: string): null | { code: string; text: string } {
    if (itemCode) {
      itemCode = itemCode.toLowerCase();
      switch (itemCode) {
        case 'none':
          return { code: 'none', text: 'None' };
        case 'notapplicable':
          return { code: 'notapplicable', text: 'Not Applicable' };
      }
    }
    return null;
  }

  isEditMode(): boolean {
    try {
      return isFormOnEditMode();
    }
    catch {
      return false;
    }
  }

  // Return the display text from the display string at answer level
  getDisplayText(display: string): string {
    const text = display.split('|');
    return text[0].trim();
  }

  // Return the help text from the display string at answer level
  getHelpText(display: string): string {
    const text = display.split('|');
    return text[1] ? ` ${text[1].trim()}` : '';
  }
}
