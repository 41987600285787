import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { WoundCareOrder } from './models/woundCareOrder.interface';

@Injectable({
  providedIn: 'root'
})
export class WoundCareOrderService {
  private woundCareOrder$ = new BehaviorSubject<WoundCareOrder | null>(null);
  private wasWoundCareOrderModified = false;

  constructor() {
    window['WoundCareOrderService'] = this;
  }

  getOrder(): Observable<WoundCareOrder | null> {
    return this.woundCareOrder$.asObservable().pipe(distinctUntilChanged());
  }

  setOrder(data: WoundCareOrder): void {
    this.woundCareOrder$.next(data);
  }

  getWasWoundCareOrderModified(): boolean {
    return this.wasWoundCareOrderModified;
  }

  setWasWoundCareOrderModified(value: boolean): void {
    this.wasWoundCareOrderModified = value;
  }
}