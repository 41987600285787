<ng-container *ngIf="show">
  <div class="container">
    <div class="title">Characteristics</div>
    <div *ngIf="hasFailed; else smartTissue">
      <div class="orange-bg">
        <div class="orange-subtitle">Smart tissue suggestions</div>
        <div class="error">Manual data entry required.<br />
          We could not generate a suggestion due to the following: HealX was not detected, trace unavailable, or there
          was
          an inability to identify epithelial, granulation, slough, or eschar tissue.</div>
      </div>
    </div>
    <ng-template #smartTissue>
      <div class="green-bg">
        <div class="subtitle">Smart tissue suggestions</div>
        <div class="description">Based on your wound image</div>
      </div>
      <div class="white-bg">
        <div class="image-container">
          <img class="image-side" src="data:image/png;base64,{{image?.bytes}}" />
          <img class="image-side" src="data:image/png;base64,{{imageMask?.bytes}}" />
        </div>
        <div class="items-container">
          <div *ngFor="let item of items" class="item">
            <div *ngIf="item.visible" class="row">
              <div class="label">{{ item.label }}</div>
              <div class="percentage">{{ item.value }}%</div>
            </div>
          </div>
        </div>
        <div class="buttons-container">
          <button class="button-decline" [disabled]="commonUtils.isEditMode()" (click)="declineAction()">
            Decline
          </button>
          <button class="button-apply" [disabled]="commonUtils.isEditMode()" (click)="applyAction()">Apply</button>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>